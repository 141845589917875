import { usePage } from "@inertiajs/vue3";

export const methods = {
    /**
     * Translate the given key.
     */
    __(key, replace = {}) {
        var translation = usePage().language[key]
            ? usePage().language[key]
            : key;

        Object.keys(replace).forEach(function (key) {
            translation = translation.replace(":" + key, replace[key]);
        });

        return translation;
    },
};
